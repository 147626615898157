<template>
  <div class="social-sentiment-insight-card d-lg-flex flex-column min-height-100">
    <div class="text-h6 font-weight-bold mb-4">
      Social Sentiment
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          Know how positively the audience responds to the influencer and their posts.
        </span>
      </v-tooltip>
    </div>

    <v-card class="border-radius-1em box-shadow-soft flex-grow-1">
      <v-card-text class="flex-grow-1 pb-0">
        <!-- Show the query, status and score here -->
        <div class="d-flex justify-end align-center">
          <!-- Show status and score here -->
          <div class="d-flex">
            <score-chip :score="userData.data.extra.social_sentiment.score" />
          </div>
        </div>

        <!-- If possible show the distribution chart here -->
        <distribution-chart
          v-if="userData.data.extra.social_sentiment.distribution"
          :data="userData.data.extra.social_sentiment.distribution"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// Import children components
import ScoreChip from '@/components/social-sentiments/ScoreChip.vue'
import DistributionChart from '@/components/social-sentiments/DistributionChart.vue'

// Export the SFC
export default {
  // Name of the component
  name: "SocialSentiment",

  // Register components
  components: {
    ScoreChip,
    DistributionChart
  },

  // Accept incoming data from parent
  props: {
    userData: {
      type: Object,
      required: true
    }
  }
}
</script>
